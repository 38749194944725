import { defineStore } from 'pinia'
import type { QuotePayload } from '~/types'
import nuxtStorage from 'nuxt-storage';

export const useQuotePayloadStore = defineStore('quote-payload', {

  state: () => ({
    quotePayload: {} as QuotePayload
  }),
  persist: true,
  actions: {
    setQuotePayloadValue (value: QuotePayload) {
      this.quotePayload = value
    },
    reset() {
      this.quotePayload = {} as QuotePayload
      nuxtStorage?.sessionStorage?.removeItem('quote-payload');
    },
  },
  getters: {
    issueStateAbbreviation: state => state.quotePayload.issueStateAbbreviation,
    primaryInsured: state => state.quotePayload.primaryInsured,
    insuredOptionApplicationValue: state => state.quotePayload.primaryInsured?.coverages?.find((coverage: Coverage) => coverage.coverageLookup === "Base")?.options?.insuredOption.applicationValue
  }
})
